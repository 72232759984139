export const reversions = [
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci99",
        "meta": {
            "name": "Reversion #100",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Fast Decay"
                },
                {
                    "trait_type": "x",
                    "value": "3"
                },
                {
                    "trait_type": "y",
                    "value": "6"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci98",
        "meta": {
            "name": "Reversion #99",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Slow Growth"
                },
                {
                    "trait_type": "x",
                    "value": "2"
                },
                {
                    "trait_type": "y",
                    "value": "6"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci97",
        "meta": {
            "name": "Reversion #98",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Dense Life"
                },
                {
                    "trait_type": "x",
                    "value": "1"
                },
                {
                    "trait_type": "y",
                    "value": "6"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci96",
        "meta": {
            "name": "Reversion #97",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Isolation"
                },
                {
                    "trait_type": "x",
                    "value": "0"
                },
                {
                    "trait_type": "y",
                    "value": "6"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci95",
        "meta": {
            "name": "Reversion #96",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Expansionist"
                },
                {
                    "trait_type": "x",
                    "value": "15"
                },
                {
                    "trait_type": "y",
                    "value": "5"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci94",
        "meta": {
            "name": "Reversion #95",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Maze Generator"
                },
                {
                    "trait_type": "x",
                    "value": "14"
                },
                {
                    "trait_type": "y",
                    "value": "5"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci93",
        "meta": {
            "name": "Reversion #94",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Stasis Growth"
                },
                {
                    "trait_type": "x",
                    "value": "13"
                },
                {
                    "trait_type": "y",
                    "value": "5"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci92",
        "meta": {
            "name": "Reversion #93",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "High Life"
                },
                {
                    "trait_type": "x",
                    "value": "12"
                },
                {
                    "trait_type": "y",
                    "value": "5"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci91",
        "meta": {
            "name": "Reversion #92",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Inversion"
                },
                {
                    "trait_type": "x",
                    "value": "11"
                },
                {
                    "trait_type": "y",
                    "value": "5"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci90",
        "meta": {
            "name": "Reversion #91",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Conway's Game of Life"
                },
                {
                    "trait_type": "x",
                    "value": "10"
                },
                {
                    "trait_type": "y",
                    "value": "5"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci9",
        "meta": {
            "name": "Reversion #10",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Fast Decay"
                },
                {
                    "trait_type": "x",
                    "value": "9"
                },
                {
                    "trait_type": "y",
                    "value": "0"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci89",
        "meta": {
            "name": "Reversion #90",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Randomness"
                },
                {
                    "trait_type": "x",
                    "value": "9"
                },
                {
                    "trait_type": "y",
                    "value": "5"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci88",
        "meta": {
            "name": "Reversion #89",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Stability"
                },
                {
                    "trait_type": "x",
                    "value": "8"
                },
                {
                    "trait_type": "y",
                    "value": "5"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci87",
        "meta": {
            "name": "Reversion #88",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Overcrowding"
                },
                {
                    "trait_type": "x",
                    "value": "7"
                },
                {
                    "trait_type": "y",
                    "value": "5"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci86",
        "meta": {
            "name": "Reversion #87",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Loneliness"
                },
                {
                    "trait_type": "x",
                    "value": "6"
                },
                {
                    "trait_type": "y",
                    "value": "5"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci85",
        "meta": {
            "name": "Reversion #86",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Revival"
                },
                {
                    "trait_type": "x",
                    "value": "5"
                },
                {
                    "trait_type": "y",
                    "value": "5"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci84",
        "meta": {
            "name": "Reversion #85",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Fast Decay"
                },
                {
                    "trait_type": "x",
                    "value": "4"
                },
                {
                    "trait_type": "y",
                    "value": "5"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci83",
        "meta": {
            "name": "Reversion #84",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Slow Growth"
                },
                {
                    "trait_type": "x",
                    "value": "3"
                },
                {
                    "trait_type": "y",
                    "value": "5"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci82",
        "meta": {
            "name": "Reversion #83",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Dense Life"
                },
                {
                    "trait_type": "x",
                    "value": "2"
                },
                {
                    "trait_type": "y",
                    "value": "5"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci81",
        "meta": {
            "name": "Reversion #82",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Isolation"
                },
                {
                    "trait_type": "x",
                    "value": "1"
                },
                {
                    "trait_type": "y",
                    "value": "5"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci80",
        "meta": {
            "name": "Reversion #81",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Expansionist"
                },
                {
                    "trait_type": "x",
                    "value": "0"
                },
                {
                    "trait_type": "y",
                    "value": "5"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci8",
        "meta": {
            "name": "Reversion #9",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Slow Growth"
                },
                {
                    "trait_type": "x",
                    "value": "8"
                },
                {
                    "trait_type": "y",
                    "value": "0"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci79",
        "meta": {
            "name": "Reversion #80",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Maze Generator"
                },
                {
                    "trait_type": "x",
                    "value": "15"
                },
                {
                    "trait_type": "y",
                    "value": "4"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci78",
        "meta": {
            "name": "Reversion #79",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Stasis Growth"
                },
                {
                    "trait_type": "x",
                    "value": "14"
                },
                {
                    "trait_type": "y",
                    "value": "4"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci77",
        "meta": {
            "name": "Reversion #78",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "High Life"
                },
                {
                    "trait_type": "x",
                    "value": "13"
                },
                {
                    "trait_type": "y",
                    "value": "4"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci76",
        "meta": {
            "name": "Reversion #77",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Inversion"
                },
                {
                    "trait_type": "x",
                    "value": "12"
                },
                {
                    "trait_type": "y",
                    "value": "4"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci75",
        "meta": {
            "name": "Reversion #76",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Conway's Game of Life"
                },
                {
                    "trait_type": "x",
                    "value": "11"
                },
                {
                    "trait_type": "y",
                    "value": "4"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci74",
        "meta": {
            "name": "Reversion #75",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Randomness"
                },
                {
                    "trait_type": "x",
                    "value": "10"
                },
                {
                    "trait_type": "y",
                    "value": "4"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci73",
        "meta": {
            "name": "Reversion #74",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Stability"
                },
                {
                    "trait_type": "x",
                    "value": "9"
                },
                {
                    "trait_type": "y",
                    "value": "4"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci72",
        "meta": {
            "name": "Reversion #73",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Overcrowding"
                },
                {
                    "trait_type": "x",
                    "value": "8"
                },
                {
                    "trait_type": "y",
                    "value": "4"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci71",
        "meta": {
            "name": "Reversion #72",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Loneliness"
                },
                {
                    "trait_type": "x",
                    "value": "7"
                },
                {
                    "trait_type": "y",
                    "value": "4"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci70",
        "meta": {
            "name": "Reversion #71",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Revival"
                },
                {
                    "trait_type": "x",
                    "value": "6"
                },
                {
                    "trait_type": "y",
                    "value": "4"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci7",
        "meta": {
            "name": "Reversion #8",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Dense Life"
                },
                {
                    "trait_type": "x",
                    "value": "7"
                },
                {
                    "trait_type": "y",
                    "value": "0"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci69",
        "meta": {
            "name": "Reversion #70",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Fast Decay"
                },
                {
                    "trait_type": "x",
                    "value": "5"
                },
                {
                    "trait_type": "y",
                    "value": "4"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci68",
        "meta": {
            "name": "Reversion #69",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Slow Growth"
                },
                {
                    "trait_type": "x",
                    "value": "4"
                },
                {
                    "trait_type": "y",
                    "value": "4"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci67",
        "meta": {
            "name": "Reversion #68",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Dense Life"
                },
                {
                    "trait_type": "x",
                    "value": "3"
                },
                {
                    "trait_type": "y",
                    "value": "4"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci66",
        "meta": {
            "name": "Reversion #67",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Isolation"
                },
                {
                    "trait_type": "x",
                    "value": "2"
                },
                {
                    "trait_type": "y",
                    "value": "4"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci65",
        "meta": {
            "name": "Reversion #66",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Expansionist"
                },
                {
                    "trait_type": "x",
                    "value": "1"
                },
                {
                    "trait_type": "y",
                    "value": "4"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci64",
        "meta": {
            "name": "Reversion #65",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Maze Generator"
                },
                {
                    "trait_type": "x",
                    "value": "0"
                },
                {
                    "trait_type": "y",
                    "value": "4"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci63",
        "meta": {
            "name": "Reversion #64",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Stasis Growth"
                },
                {
                    "trait_type": "x",
                    "value": "15"
                },
                {
                    "trait_type": "y",
                    "value": "3"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci62",
        "meta": {
            "name": "Reversion #63",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "High Life"
                },
                {
                    "trait_type": "x",
                    "value": "14"
                },
                {
                    "trait_type": "y",
                    "value": "3"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci61",
        "meta": {
            "name": "Reversion #62",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Inversion"
                },
                {
                    "trait_type": "x",
                    "value": "13"
                },
                {
                    "trait_type": "y",
                    "value": "3"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci60",
        "meta": {
            "name": "Reversion #61",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Conway's Game of Life"
                },
                {
                    "trait_type": "x",
                    "value": "12"
                },
                {
                    "trait_type": "y",
                    "value": "3"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci6",
        "meta": {
            "name": "Reversion #7",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Isolation"
                },
                {
                    "trait_type": "x",
                    "value": "6"
                },
                {
                    "trait_type": "y",
                    "value": "0"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci59",
        "meta": {
            "name": "Reversion #60",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Randomness"
                },
                {
                    "trait_type": "x",
                    "value": "11"
                },
                {
                    "trait_type": "y",
                    "value": "3"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci58",
        "meta": {
            "name": "Reversion #59",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Stability"
                },
                {
                    "trait_type": "x",
                    "value": "10"
                },
                {
                    "trait_type": "y",
                    "value": "3"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci57",
        "meta": {
            "name": "Reversion #58",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Overcrowding"
                },
                {
                    "trait_type": "x",
                    "value": "9"
                },
                {
                    "trait_type": "y",
                    "value": "3"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci56",
        "meta": {
            "name": "Reversion #57",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Loneliness"
                },
                {
                    "trait_type": "x",
                    "value": "8"
                },
                {
                    "trait_type": "y",
                    "value": "3"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci55",
        "meta": {
            "name": "Reversion #56",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Revival"
                },
                {
                    "trait_type": "x",
                    "value": "7"
                },
                {
                    "trait_type": "y",
                    "value": "3"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci54",
        "meta": {
            "name": "Reversion #55",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Fast Decay"
                },
                {
                    "trait_type": "x",
                    "value": "6"
                },
                {
                    "trait_type": "y",
                    "value": "3"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci53",
        "meta": {
            "name": "Reversion #54",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Slow Growth"
                },
                {
                    "trait_type": "x",
                    "value": "5"
                },
                {
                    "trait_type": "y",
                    "value": "3"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci52",
        "meta": {
            "name": "Reversion #53",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Dense Life"
                },
                {
                    "trait_type": "x",
                    "value": "4"
                },
                {
                    "trait_type": "y",
                    "value": "3"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci51",
        "meta": {
            "name": "Reversion #52",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Isolation"
                },
                {
                    "trait_type": "x",
                    "value": "3"
                },
                {
                    "trait_type": "y",
                    "value": "3"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci50",
        "meta": {
            "name": "Reversion #51",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Expansionist"
                },
                {
                    "trait_type": "x",
                    "value": "2"
                },
                {
                    "trait_type": "y",
                    "value": "3"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci5",
        "meta": {
            "name": "Reversion #6",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Expansionist"
                },
                {
                    "trait_type": "x",
                    "value": "5"
                },
                {
                    "trait_type": "y",
                    "value": "0"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci49",
        "meta": {
            "name": "Reversion #50",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Maze Generator"
                },
                {
                    "trait_type": "x",
                    "value": "1"
                },
                {
                    "trait_type": "y",
                    "value": "3"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci48",
        "meta": {
            "name": "Reversion #49",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Stasis Growth"
                },
                {
                    "trait_type": "x",
                    "value": "0"
                },
                {
                    "trait_type": "y",
                    "value": "3"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci47",
        "meta": {
            "name": "Reversion #48",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "High Life"
                },
                {
                    "trait_type": "x",
                    "value": "15"
                },
                {
                    "trait_type": "y",
                    "value": "2"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci46",
        "meta": {
            "name": "Reversion #47",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Inversion"
                },
                {
                    "trait_type": "x",
                    "value": "14"
                },
                {
                    "trait_type": "y",
                    "value": "2"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci45",
        "meta": {
            "name": "Reversion #46",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Conway's Game of Life"
                },
                {
                    "trait_type": "x",
                    "value": "13"
                },
                {
                    "trait_type": "y",
                    "value": "2"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci44",
        "meta": {
            "name": "Reversion #45",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Randomness"
                },
                {
                    "trait_type": "x",
                    "value": "12"
                },
                {
                    "trait_type": "y",
                    "value": "2"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci43",
        "meta": {
            "name": "Reversion #44",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Stability"
                },
                {
                    "trait_type": "x",
                    "value": "11"
                },
                {
                    "trait_type": "y",
                    "value": "2"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci42",
        "meta": {
            "name": "Reversion #43",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Overcrowding"
                },
                {
                    "trait_type": "x",
                    "value": "10"
                },
                {
                    "trait_type": "y",
                    "value": "2"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci41",
        "meta": {
            "name": "Reversion #42",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Loneliness"
                },
                {
                    "trait_type": "x",
                    "value": "9"
                },
                {
                    "trait_type": "y",
                    "value": "2"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci40",
        "meta": {
            "name": "Reversion #41",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Revival"
                },
                {
                    "trait_type": "x",
                    "value": "8"
                },
                {
                    "trait_type": "y",
                    "value": "2"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci4",
        "meta": {
            "name": "Reversion #5",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Maze Generator"
                },
                {
                    "trait_type": "x",
                    "value": "4"
                },
                {
                    "trait_type": "y",
                    "value": "0"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci39",
        "meta": {
            "name": "Reversion #40",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Fast Decay"
                },
                {
                    "trait_type": "x",
                    "value": "7"
                },
                {
                    "trait_type": "y",
                    "value": "2"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci38",
        "meta": {
            "name": "Reversion #39",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Slow Growth"
                },
                {
                    "trait_type": "x",
                    "value": "6"
                },
                {
                    "trait_type": "y",
                    "value": "2"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci37",
        "meta": {
            "name": "Reversion #38",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Dense Life"
                },
                {
                    "trait_type": "x",
                    "value": "5"
                },
                {
                    "trait_type": "y",
                    "value": "2"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci36",
        "meta": {
            "name": "Reversion #37",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Isolation"
                },
                {
                    "trait_type": "x",
                    "value": "4"
                },
                {
                    "trait_type": "y",
                    "value": "2"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci35",
        "meta": {
            "name": "Reversion #36",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Expansionist"
                },
                {
                    "trait_type": "x",
                    "value": "3"
                },
                {
                    "trait_type": "y",
                    "value": "2"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci34",
        "meta": {
            "name": "Reversion #35",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Maze Generator"
                },
                {
                    "trait_type": "x",
                    "value": "2"
                },
                {
                    "trait_type": "y",
                    "value": "2"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci33",
        "meta": {
            "name": "Reversion #34",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Stasis Growth"
                },
                {
                    "trait_type": "x",
                    "value": "1"
                },
                {
                    "trait_type": "y",
                    "value": "2"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci32",
        "meta": {
            "name": "Reversion #33",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "High Life"
                },
                {
                    "trait_type": "x",
                    "value": "0"
                },
                {
                    "trait_type": "y",
                    "value": "2"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci31",
        "meta": {
            "name": "Reversion #32",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Inversion"
                },
                {
                    "trait_type": "x",
                    "value": "15"
                },
                {
                    "trait_type": "y",
                    "value": "1"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci30",
        "meta": {
            "name": "Reversion #31",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Conway's Game of Life"
                },
                {
                    "trait_type": "x",
                    "value": "14"
                },
                {
                    "trait_type": "y",
                    "value": "1"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci3",
        "meta": {
            "name": "Reversion #4",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Stasis Growth"
                },
                {
                    "trait_type": "x",
                    "value": "3"
                },
                {
                    "trait_type": "y",
                    "value": "0"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci29",
        "meta": {
            "name": "Reversion #30",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Randomness"
                },
                {
                    "trait_type": "x",
                    "value": "13"
                },
                {
                    "trait_type": "y",
                    "value": "1"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci28",
        "meta": {
            "name": "Reversion #29",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Stability"
                },
                {
                    "trait_type": "x",
                    "value": "12"
                },
                {
                    "trait_type": "y",
                    "value": "1"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci27",
        "meta": {
            "name": "Reversion #28",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Overcrowding"
                },
                {
                    "trait_type": "x",
                    "value": "11"
                },
                {
                    "trait_type": "y",
                    "value": "1"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci26",
        "meta": {
            "name": "Reversion #27",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Loneliness"
                },
                {
                    "trait_type": "x",
                    "value": "10"
                },
                {
                    "trait_type": "y",
                    "value": "1"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci255",
        "meta": {
            "name": "Reversion #256",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Conway's Game of Life"
                },
                {
                    "trait_type": "x",
                    "value": "15"
                },
                {
                    "trait_type": "y",
                    "value": "15"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci254",
        "meta": {
            "name": "Reversion #255",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Randomness"
                },
                {
                    "trait_type": "x",
                    "value": "14"
                },
                {
                    "trait_type": "y",
                    "value": "15"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci253",
        "meta": {
            "name": "Reversion #254",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Stability"
                },
                {
                    "trait_type": "x",
                    "value": "13"
                },
                {
                    "trait_type": "y",
                    "value": "15"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci252",
        "meta": {
            "name": "Reversion #253",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Overcrowding"
                },
                {
                    "trait_type": "x",
                    "value": "12"
                },
                {
                    "trait_type": "y",
                    "value": "15"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci251",
        "meta": {
            "name": "Reversion #252",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Loneliness"
                },
                {
                    "trait_type": "x",
                    "value": "11"
                },
                {
                    "trait_type": "y",
                    "value": "15"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci250",
        "meta": {
            "name": "Reversion #251",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Revival"
                },
                {
                    "trait_type": "x",
                    "value": "10"
                },
                {
                    "trait_type": "y",
                    "value": "15"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci25",
        "meta": {
            "name": "Reversion #26",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Revival"
                },
                {
                    "trait_type": "x",
                    "value": "9"
                },
                {
                    "trait_type": "y",
                    "value": "1"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci249",
        "meta": {
            "name": "Reversion #250",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Fast Decay"
                },
                {
                    "trait_type": "x",
                    "value": "9"
                },
                {
                    "trait_type": "y",
                    "value": "15"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci248",
        "meta": {
            "name": "Reversion #249",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Slow Growth"
                },
                {
                    "trait_type": "x",
                    "value": "8"
                },
                {
                    "trait_type": "y",
                    "value": "15"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci247",
        "meta": {
            "name": "Reversion #248",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Dense Life"
                },
                {
                    "trait_type": "x",
                    "value": "7"
                },
                {
                    "trait_type": "y",
                    "value": "15"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci246",
        "meta": {
            "name": "Reversion #247",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Isolation"
                },
                {
                    "trait_type": "x",
                    "value": "6"
                },
                {
                    "trait_type": "y",
                    "value": "15"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci245",
        "meta": {
            "name": "Reversion #246",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Expansionist"
                },
                {
                    "trait_type": "x",
                    "value": "5"
                },
                {
                    "trait_type": "y",
                    "value": "15"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci244",
        "meta": {
            "name": "Reversion #245",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Maze Generator"
                },
                {
                    "trait_type": "x",
                    "value": "4"
                },
                {
                    "trait_type": "y",
                    "value": "15"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci243",
        "meta": {
            "name": "Reversion #244",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Stasis Growth"
                },
                {
                    "trait_type": "x",
                    "value": "3"
                },
                {
                    "trait_type": "y",
                    "value": "15"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci242",
        "meta": {
            "name": "Reversion #243",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "High Life"
                },
                {
                    "trait_type": "x",
                    "value": "2"
                },
                {
                    "trait_type": "y",
                    "value": "15"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci241",
        "meta": {
            "name": "Reversion #242",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Inversion"
                },
                {
                    "trait_type": "x",
                    "value": "1"
                },
                {
                    "trait_type": "y",
                    "value": "15"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci240",
        "meta": {
            "name": "Reversion #241",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Conway's Game of Life"
                },
                {
                    "trait_type": "x",
                    "value": "0"
                },
                {
                    "trait_type": "y",
                    "value": "15"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci24",
        "meta": {
            "name": "Reversion #25",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Fast Decay"
                },
                {
                    "trait_type": "x",
                    "value": "8"
                },
                {
                    "trait_type": "y",
                    "value": "1"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci239",
        "meta": {
            "name": "Reversion #240",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Randomness"
                },
                {
                    "trait_type": "x",
                    "value": "15"
                },
                {
                    "trait_type": "y",
                    "value": "14"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci238",
        "meta": {
            "name": "Reversion #239",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Stability"
                },
                {
                    "trait_type": "x",
                    "value": "14"
                },
                {
                    "trait_type": "y",
                    "value": "14"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci237",
        "meta": {
            "name": "Reversion #238",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Overcrowding"
                },
                {
                    "trait_type": "x",
                    "value": "13"
                },
                {
                    "trait_type": "y",
                    "value": "14"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci236",
        "meta": {
            "name": "Reversion #237",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Loneliness"
                },
                {
                    "trait_type": "x",
                    "value": "12"
                },
                {
                    "trait_type": "y",
                    "value": "14"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci235",
        "meta": {
            "name": "Reversion #236",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Revival"
                },
                {
                    "trait_type": "x",
                    "value": "11"
                },
                {
                    "trait_type": "y",
                    "value": "14"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci234",
        "meta": {
            "name": "Reversion #235",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Fast Decay"
                },
                {
                    "trait_type": "x",
                    "value": "10"
                },
                {
                    "trait_type": "y",
                    "value": "14"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci233",
        "meta": {
            "name": "Reversion #234",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Slow Growth"
                },
                {
                    "trait_type": "x",
                    "value": "9"
                },
                {
                    "trait_type": "y",
                    "value": "14"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci232",
        "meta": {
            "name": "Reversion #233",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Dense Life"
                },
                {
                    "trait_type": "x",
                    "value": "8"
                },
                {
                    "trait_type": "y",
                    "value": "14"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci231",
        "meta": {
            "name": "Reversion #232",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Isolation"
                },
                {
                    "trait_type": "x",
                    "value": "7"
                },
                {
                    "trait_type": "y",
                    "value": "14"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci230",
        "meta": {
            "name": "Reversion #231",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Expansionist"
                },
                {
                    "trait_type": "x",
                    "value": "6"
                },
                {
                    "trait_type": "y",
                    "value": "14"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci23",
        "meta": {
            "name": "Reversion #24",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Slow Growth"
                },
                {
                    "trait_type": "x",
                    "value": "7"
                },
                {
                    "trait_type": "y",
                    "value": "1"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci229",
        "meta": {
            "name": "Reversion #230",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Maze Generator"
                },
                {
                    "trait_type": "x",
                    "value": "5"
                },
                {
                    "trait_type": "y",
                    "value": "14"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci228",
        "meta": {
            "name": "Reversion #229",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Stasis Growth"
                },
                {
                    "trait_type": "x",
                    "value": "4"
                },
                {
                    "trait_type": "y",
                    "value": "14"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci227",
        "meta": {
            "name": "Reversion #228",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "High Life"
                },
                {
                    "trait_type": "x",
                    "value": "3"
                },
                {
                    "trait_type": "y",
                    "value": "14"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci226",
        "meta": {
            "name": "Reversion #227",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Inversion"
                },
                {
                    "trait_type": "x",
                    "value": "2"
                },
                {
                    "trait_type": "y",
                    "value": "14"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci225",
        "meta": {
            "name": "Reversion #226",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Conway's Game of Life"
                },
                {
                    "trait_type": "x",
                    "value": "1"
                },
                {
                    "trait_type": "y",
                    "value": "14"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci224",
        "meta": {
            "name": "Reversion #225",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Randomness"
                },
                {
                    "trait_type": "x",
                    "value": "0"
                },
                {
                    "trait_type": "y",
                    "value": "14"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci223",
        "meta": {
            "name": "Reversion #224",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Stability"
                },
                {
                    "trait_type": "x",
                    "value": "15"
                },
                {
                    "trait_type": "y",
                    "value": "13"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci222",
        "meta": {
            "name": "Reversion #223",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Overcrowding"
                },
                {
                    "trait_type": "x",
                    "value": "14"
                },
                {
                    "trait_type": "y",
                    "value": "13"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci221",
        "meta": {
            "name": "Reversion #222",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Loneliness"
                },
                {
                    "trait_type": "x",
                    "value": "13"
                },
                {
                    "trait_type": "y",
                    "value": "13"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci220",
        "meta": {
            "name": "Reversion #221",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Revival"
                },
                {
                    "trait_type": "x",
                    "value": "12"
                },
                {
                    "trait_type": "y",
                    "value": "13"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci22",
        "meta": {
            "name": "Reversion #23",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Dense Life"
                },
                {
                    "trait_type": "x",
                    "value": "6"
                },
                {
                    "trait_type": "y",
                    "value": "1"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci219",
        "meta": {
            "name": "Reversion #220",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Fast Decay"
                },
                {
                    "trait_type": "x",
                    "value": "11"
                },
                {
                    "trait_type": "y",
                    "value": "13"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci218",
        "meta": {
            "name": "Reversion #219",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Slow Growth"
                },
                {
                    "trait_type": "x",
                    "value": "10"
                },
                {
                    "trait_type": "y",
                    "value": "13"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci217",
        "meta": {
            "name": "Reversion #218",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Dense Life"
                },
                {
                    "trait_type": "x",
                    "value": "9"
                },
                {
                    "trait_type": "y",
                    "value": "13"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci216",
        "meta": {
            "name": "Reversion #217",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Isolation"
                },
                {
                    "trait_type": "x",
                    "value": "8"
                },
                {
                    "trait_type": "y",
                    "value": "13"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci215",
        "meta": {
            "name": "Reversion #216",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Expansionist"
                },
                {
                    "trait_type": "x",
                    "value": "7"
                },
                {
                    "trait_type": "y",
                    "value": "13"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci214",
        "meta": {
            "name": "Reversion #215",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Maze Generator"
                },
                {
                    "trait_type": "x",
                    "value": "6"
                },
                {
                    "trait_type": "y",
                    "value": "13"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci213",
        "meta": {
            "name": "Reversion #214",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Stasis Growth"
                },
                {
                    "trait_type": "x",
                    "value": "5"
                },
                {
                    "trait_type": "y",
                    "value": "13"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci212",
        "meta": {
            "name": "Reversion #213",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "High Life"
                },
                {
                    "trait_type": "x",
                    "value": "4"
                },
                {
                    "trait_type": "y",
                    "value": "13"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci211",
        "meta": {
            "name": "Reversion #212",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Inversion"
                },
                {
                    "trait_type": "x",
                    "value": "3"
                },
                {
                    "trait_type": "y",
                    "value": "13"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci210",
        "meta": {
            "name": "Reversion #211",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Conway's Game of Life"
                },
                {
                    "trait_type": "x",
                    "value": "2"
                },
                {
                    "trait_type": "y",
                    "value": "13"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci21",
        "meta": {
            "name": "Reversion #22",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Isolation"
                },
                {
                    "trait_type": "x",
                    "value": "5"
                },
                {
                    "trait_type": "y",
                    "value": "1"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci209",
        "meta": {
            "name": "Reversion #210",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Randomness"
                },
                {
                    "trait_type": "x",
                    "value": "1"
                },
                {
                    "trait_type": "y",
                    "value": "13"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci208",
        "meta": {
            "name": "Reversion #209",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Stability"
                },
                {
                    "trait_type": "x",
                    "value": "0"
                },
                {
                    "trait_type": "y",
                    "value": "13"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci207",
        "meta": {
            "name": "Reversion #208",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Overcrowding"
                },
                {
                    "trait_type": "x",
                    "value": "15"
                },
                {
                    "trait_type": "y",
                    "value": "12"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci206",
        "meta": {
            "name": "Reversion #207",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Loneliness"
                },
                {
                    "trait_type": "x",
                    "value": "14"
                },
                {
                    "trait_type": "y",
                    "value": "12"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci205",
        "meta": {
            "name": "Reversion #206",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Revival"
                },
                {
                    "trait_type": "x",
                    "value": "13"
                },
                {
                    "trait_type": "y",
                    "value": "12"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci204",
        "meta": {
            "name": "Reversion #205",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Fast Decay"
                },
                {
                    "trait_type": "x",
                    "value": "12"
                },
                {
                    "trait_type": "y",
                    "value": "12"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci203",
        "meta": {
            "name": "Reversion #204",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Slow Growth"
                },
                {
                    "trait_type": "x",
                    "value": "11"
                },
                {
                    "trait_type": "y",
                    "value": "12"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci202",
        "meta": {
            "name": "Reversion #203",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Dense Life"
                },
                {
                    "trait_type": "x",
                    "value": "10"
                },
                {
                    "trait_type": "y",
                    "value": "12"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci201",
        "meta": {
            "name": "Reversion #202",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Isolation"
                },
                {
                    "trait_type": "x",
                    "value": "9"
                },
                {
                    "trait_type": "y",
                    "value": "12"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci200",
        "meta": {
            "name": "Reversion #201",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Expansionist"
                },
                {
                    "trait_type": "x",
                    "value": "8"
                },
                {
                    "trait_type": "y",
                    "value": "12"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci20",
        "meta": {
            "name": "Reversion #21",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Expansionist"
                },
                {
                    "trait_type": "x",
                    "value": "4"
                },
                {
                    "trait_type": "y",
                    "value": "1"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci2",
        "meta": {
            "name": "Reversion #3",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "High Life"
                },
                {
                    "trait_type": "x",
                    "value": "2"
                },
                {
                    "trait_type": "y",
                    "value": "0"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci199",
        "meta": {
            "name": "Reversion #200",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Maze Generator"
                },
                {
                    "trait_type": "x",
                    "value": "7"
                },
                {
                    "trait_type": "y",
                    "value": "12"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci198",
        "meta": {
            "name": "Reversion #199",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Stasis Growth"
                },
                {
                    "trait_type": "x",
                    "value": "6"
                },
                {
                    "trait_type": "y",
                    "value": "12"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci197",
        "meta": {
            "name": "Reversion #198",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "High Life"
                },
                {
                    "trait_type": "x",
                    "value": "5"
                },
                {
                    "trait_type": "y",
                    "value": "12"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci196",
        "meta": {
            "name": "Reversion #197",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Inversion"
                },
                {
                    "trait_type": "x",
                    "value": "4"
                },
                {
                    "trait_type": "y",
                    "value": "12"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci195",
        "meta": {
            "name": "Reversion #196",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Conway's Game of Life"
                },
                {
                    "trait_type": "x",
                    "value": "3"
                },
                {
                    "trait_type": "y",
                    "value": "12"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci194",
        "meta": {
            "name": "Reversion #195",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Randomness"
                },
                {
                    "trait_type": "x",
                    "value": "2"
                },
                {
                    "trait_type": "y",
                    "value": "12"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci193",
        "meta": {
            "name": "Reversion #194",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Stability"
                },
                {
                    "trait_type": "x",
                    "value": "1"
                },
                {
                    "trait_type": "y",
                    "value": "12"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci192",
        "meta": {
            "name": "Reversion #193",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Overcrowding"
                },
                {
                    "trait_type": "x",
                    "value": "0"
                },
                {
                    "trait_type": "y",
                    "value": "12"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci191",
        "meta": {
            "name": "Reversion #192",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Loneliness"
                },
                {
                    "trait_type": "x",
                    "value": "15"
                },
                {
                    "trait_type": "y",
                    "value": "11"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci190",
        "meta": {
            "name": "Reversion #191",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Revival"
                },
                {
                    "trait_type": "x",
                    "value": "14"
                },
                {
                    "trait_type": "y",
                    "value": "11"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci19",
        "meta": {
            "name": "Reversion #20",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Maze Generator"
                },
                {
                    "trait_type": "x",
                    "value": "3"
                },
                {
                    "trait_type": "y",
                    "value": "1"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci189",
        "meta": {
            "name": "Reversion #190",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Fast Decay"
                },
                {
                    "trait_type": "x",
                    "value": "13"
                },
                {
                    "trait_type": "y",
                    "value": "11"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci188",
        "meta": {
            "name": "Reversion #189",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Slow Growth"
                },
                {
                    "trait_type": "x",
                    "value": "12"
                },
                {
                    "trait_type": "y",
                    "value": "11"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci187",
        "meta": {
            "name": "Reversion #188",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Dense Life"
                },
                {
                    "trait_type": "x",
                    "value": "11"
                },
                {
                    "trait_type": "y",
                    "value": "11"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci186",
        "meta": {
            "name": "Reversion #187",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Isolation"
                },
                {
                    "trait_type": "x",
                    "value": "10"
                },
                {
                    "trait_type": "y",
                    "value": "11"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci185",
        "meta": {
            "name": "Reversion #186",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Expansionist"
                },
                {
                    "trait_type": "x",
                    "value": "9"
                },
                {
                    "trait_type": "y",
                    "value": "11"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci184",
        "meta": {
            "name": "Reversion #185",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Maze Generator"
                },
                {
                    "trait_type": "x",
                    "value": "8"
                },
                {
                    "trait_type": "y",
                    "value": "11"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci183",
        "meta": {
            "name": "Reversion #184",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Stasis Growth"
                },
                {
                    "trait_type": "x",
                    "value": "7"
                },
                {
                    "trait_type": "y",
                    "value": "11"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci182",
        "meta": {
            "name": "Reversion #183",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "High Life"
                },
                {
                    "trait_type": "x",
                    "value": "6"
                },
                {
                    "trait_type": "y",
                    "value": "11"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci181",
        "meta": {
            "name": "Reversion #182",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Inversion"
                },
                {
                    "trait_type": "x",
                    "value": "5"
                },
                {
                    "trait_type": "y",
                    "value": "11"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci180",
        "meta": {
            "name": "Reversion #181",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Conway's Game of Life"
                },
                {
                    "trait_type": "x",
                    "value": "4"
                },
                {
                    "trait_type": "y",
                    "value": "11"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci18",
        "meta": {
            "name": "Reversion #19",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Stasis Growth"
                },
                {
                    "trait_type": "x",
                    "value": "2"
                },
                {
                    "trait_type": "y",
                    "value": "1"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci179",
        "meta": {
            "name": "Reversion #180",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Randomness"
                },
                {
                    "trait_type": "x",
                    "value": "3"
                },
                {
                    "trait_type": "y",
                    "value": "11"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci178",
        "meta": {
            "name": "Reversion #179",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Stability"
                },
                {
                    "trait_type": "x",
                    "value": "2"
                },
                {
                    "trait_type": "y",
                    "value": "11"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci177",
        "meta": {
            "name": "Reversion #178",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Overcrowding"
                },
                {
                    "trait_type": "x",
                    "value": "1"
                },
                {
                    "trait_type": "y",
                    "value": "11"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci176",
        "meta": {
            "name": "Reversion #177",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Loneliness"
                },
                {
                    "trait_type": "x",
                    "value": "0"
                },
                {
                    "trait_type": "y",
                    "value": "11"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci175",
        "meta": {
            "name": "Reversion #176",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Revival"
                },
                {
                    "trait_type": "x",
                    "value": "15"
                },
                {
                    "trait_type": "y",
                    "value": "10"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci174",
        "meta": {
            "name": "Reversion #175",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Fast Decay"
                },
                {
                    "trait_type": "x",
                    "value": "14"
                },
                {
                    "trait_type": "y",
                    "value": "10"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci173",
        "meta": {
            "name": "Reversion #174",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Slow Growth"
                },
                {
                    "trait_type": "x",
                    "value": "13"
                },
                {
                    "trait_type": "y",
                    "value": "10"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci172",
        "meta": {
            "name": "Reversion #173",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Dense Life"
                },
                {
                    "trait_type": "x",
                    "value": "12"
                },
                {
                    "trait_type": "y",
                    "value": "10"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci171",
        "meta": {
            "name": "Reversion #172",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Isolation"
                },
                {
                    "trait_type": "x",
                    "value": "11"
                },
                {
                    "trait_type": "y",
                    "value": "10"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci170",
        "meta": {
            "name": "Reversion #171",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Expansionist"
                },
                {
                    "trait_type": "x",
                    "value": "10"
                },
                {
                    "trait_type": "y",
                    "value": "10"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci17",
        "meta": {
            "name": "Reversion #18",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "High Life"
                },
                {
                    "trait_type": "x",
                    "value": "1"
                },
                {
                    "trait_type": "y",
                    "value": "1"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci169",
        "meta": {
            "name": "Reversion #170",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Maze Generator"
                },
                {
                    "trait_type": "x",
                    "value": "9"
                },
                {
                    "trait_type": "y",
                    "value": "10"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci168",
        "meta": {
            "name": "Reversion #169",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Stasis Growth"
                },
                {
                    "trait_type": "x",
                    "value": "8"
                },
                {
                    "trait_type": "y",
                    "value": "10"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci167",
        "meta": {
            "name": "Reversion #168",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "High Life"
                },
                {
                    "trait_type": "x",
                    "value": "7"
                },
                {
                    "trait_type": "y",
                    "value": "10"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci166",
        "meta": {
            "name": "Reversion #167",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Inversion"
                },
                {
                    "trait_type": "x",
                    "value": "6"
                },
                {
                    "trait_type": "y",
                    "value": "10"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci165",
        "meta": {
            "name": "Reversion #166",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Conway's Game of Life"
                },
                {
                    "trait_type": "x",
                    "value": "5"
                },
                {
                    "trait_type": "y",
                    "value": "10"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci164",
        "meta": {
            "name": "Reversion #165",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Randomness"
                },
                {
                    "trait_type": "x",
                    "value": "4"
                },
                {
                    "trait_type": "y",
                    "value": "10"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci163",
        "meta": {
            "name": "Reversion #164",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Stability"
                },
                {
                    "trait_type": "x",
                    "value": "3"
                },
                {
                    "trait_type": "y",
                    "value": "10"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci162",
        "meta": {
            "name": "Reversion #163",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Overcrowding"
                },
                {
                    "trait_type": "x",
                    "value": "2"
                },
                {
                    "trait_type": "y",
                    "value": "10"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci161",
        "meta": {
            "name": "Reversion #162",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Loneliness"
                },
                {
                    "trait_type": "x",
                    "value": "1"
                },
                {
                    "trait_type": "y",
                    "value": "10"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci160",
        "meta": {
            "name": "Reversion #161",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Revival"
                },
                {
                    "trait_type": "x",
                    "value": "0"
                },
                {
                    "trait_type": "y",
                    "value": "10"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci16",
        "meta": {
            "name": "Reversion #17",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Inversion"
                },
                {
                    "trait_type": "x",
                    "value": "0"
                },
                {
                    "trait_type": "y",
                    "value": "1"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci159",
        "meta": {
            "name": "Reversion #160",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Fast Decay"
                },
                {
                    "trait_type": "x",
                    "value": "15"
                },
                {
                    "trait_type": "y",
                    "value": "9"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci158",
        "meta": {
            "name": "Reversion #159",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Slow Growth"
                },
                {
                    "trait_type": "x",
                    "value": "14"
                },
                {
                    "trait_type": "y",
                    "value": "9"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci157",
        "meta": {
            "name": "Reversion #158",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Dense Life"
                },
                {
                    "trait_type": "x",
                    "value": "13"
                },
                {
                    "trait_type": "y",
                    "value": "9"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci156",
        "meta": {
            "name": "Reversion #157",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Isolation"
                },
                {
                    "trait_type": "x",
                    "value": "12"
                },
                {
                    "trait_type": "y",
                    "value": "9"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci155",
        "meta": {
            "name": "Reversion #156",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Expansionist"
                },
                {
                    "trait_type": "x",
                    "value": "11"
                },
                {
                    "trait_type": "y",
                    "value": "9"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci154",
        "meta": {
            "name": "Reversion #155",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Maze Generator"
                },
                {
                    "trait_type": "x",
                    "value": "10"
                },
                {
                    "trait_type": "y",
                    "value": "9"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci153",
        "meta": {
            "name": "Reversion #154",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Stasis Growth"
                },
                {
                    "trait_type": "x",
                    "value": "9"
                },
                {
                    "trait_type": "y",
                    "value": "9"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci152",
        "meta": {
            "name": "Reversion #153",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "High Life"
                },
                {
                    "trait_type": "x",
                    "value": "8"
                },
                {
                    "trait_type": "y",
                    "value": "9"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci151",
        "meta": {
            "name": "Reversion #152",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Inversion"
                },
                {
                    "trait_type": "x",
                    "value": "7"
                },
                {
                    "trait_type": "y",
                    "value": "9"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci150",
        "meta": {
            "name": "Reversion #151",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Conway's Game of Life"
                },
                {
                    "trait_type": "x",
                    "value": "6"
                },
                {
                    "trait_type": "y",
                    "value": "9"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci15",
        "meta": {
            "name": "Reversion #16",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Conway's Game of Life"
                },
                {
                    "trait_type": "x",
                    "value": "15"
                },
                {
                    "trait_type": "y",
                    "value": "0"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci149",
        "meta": {
            "name": "Reversion #150",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Randomness"
                },
                {
                    "trait_type": "x",
                    "value": "5"
                },
                {
                    "trait_type": "y",
                    "value": "9"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci148",
        "meta": {
            "name": "Reversion #149",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Stability"
                },
                {
                    "trait_type": "x",
                    "value": "4"
                },
                {
                    "trait_type": "y",
                    "value": "9"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci147",
        "meta": {
            "name": "Reversion #148",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Overcrowding"
                },
                {
                    "trait_type": "x",
                    "value": "3"
                },
                {
                    "trait_type": "y",
                    "value": "9"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci146",
        "meta": {
            "name": "Reversion #147",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Loneliness"
                },
                {
                    "trait_type": "x",
                    "value": "2"
                },
                {
                    "trait_type": "y",
                    "value": "9"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci145",
        "meta": {
            "name": "Reversion #146",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Revival"
                },
                {
                    "trait_type": "x",
                    "value": "1"
                },
                {
                    "trait_type": "y",
                    "value": "9"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci144",
        "meta": {
            "name": "Reversion #145",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Fast Decay"
                },
                {
                    "trait_type": "x",
                    "value": "0"
                },
                {
                    "trait_type": "y",
                    "value": "9"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci143",
        "meta": {
            "name": "Reversion #144",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Slow Growth"
                },
                {
                    "trait_type": "x",
                    "value": "15"
                },
                {
                    "trait_type": "y",
                    "value": "8"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci142",
        "meta": {
            "name": "Reversion #143",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Dense Life"
                },
                {
                    "trait_type": "x",
                    "value": "14"
                },
                {
                    "trait_type": "y",
                    "value": "8"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci141",
        "meta": {
            "name": "Reversion #142",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Isolation"
                },
                {
                    "trait_type": "x",
                    "value": "13"
                },
                {
                    "trait_type": "y",
                    "value": "8"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci140",
        "meta": {
            "name": "Reversion #141",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Expansionist"
                },
                {
                    "trait_type": "x",
                    "value": "12"
                },
                {
                    "trait_type": "y",
                    "value": "8"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci14",
        "meta": {
            "name": "Reversion #15",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Randomness"
                },
                {
                    "trait_type": "x",
                    "value": "14"
                },
                {
                    "trait_type": "y",
                    "value": "0"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci139",
        "meta": {
            "name": "Reversion #140",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Maze Generator"
                },
                {
                    "trait_type": "x",
                    "value": "11"
                },
                {
                    "trait_type": "y",
                    "value": "8"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci138",
        "meta": {
            "name": "Reversion #139",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Stasis Growth"
                },
                {
                    "trait_type": "x",
                    "value": "10"
                },
                {
                    "trait_type": "y",
                    "value": "8"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci137",
        "meta": {
            "name": "Reversion #138",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "High Life"
                },
                {
                    "trait_type": "x",
                    "value": "9"
                },
                {
                    "trait_type": "y",
                    "value": "8"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci136",
        "meta": {
            "name": "Reversion #137",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Inversion"
                },
                {
                    "trait_type": "x",
                    "value": "8"
                },
                {
                    "trait_type": "y",
                    "value": "8"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci135",
        "meta": {
            "name": "Reversion #136",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Conway's Game of Life"
                },
                {
                    "trait_type": "x",
                    "value": "7"
                },
                {
                    "trait_type": "y",
                    "value": "8"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci134",
        "meta": {
            "name": "Reversion #135",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Randomness"
                },
                {
                    "trait_type": "x",
                    "value": "6"
                },
                {
                    "trait_type": "y",
                    "value": "8"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci133",
        "meta": {
            "name": "Reversion #134",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Stability"
                },
                {
                    "trait_type": "x",
                    "value": "5"
                },
                {
                    "trait_type": "y",
                    "value": "8"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci132",
        "meta": {
            "name": "Reversion #133",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Overcrowding"
                },
                {
                    "trait_type": "x",
                    "value": "4"
                },
                {
                    "trait_type": "y",
                    "value": "8"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci131",
        "meta": {
            "name": "Reversion #132",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Loneliness"
                },
                {
                    "trait_type": "x",
                    "value": "3"
                },
                {
                    "trait_type": "y",
                    "value": "8"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci130",
        "meta": {
            "name": "Reversion #131",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Revival"
                },
                {
                    "trait_type": "x",
                    "value": "2"
                },
                {
                    "trait_type": "y",
                    "value": "8"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci13",
        "meta": {
            "name": "Reversion #14",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Stability"
                },
                {
                    "trait_type": "x",
                    "value": "13"
                },
                {
                    "trait_type": "y",
                    "value": "0"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci129",
        "meta": {
            "name": "Reversion #130",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Fast Decay"
                },
                {
                    "trait_type": "x",
                    "value": "1"
                },
                {
                    "trait_type": "y",
                    "value": "8"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci128",
        "meta": {
            "name": "Reversion #129",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Slow Growth"
                },
                {
                    "trait_type": "x",
                    "value": "0"
                },
                {
                    "trait_type": "y",
                    "value": "8"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci127",
        "meta": {
            "name": "Reversion #128",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Dense Life"
                },
                {
                    "trait_type": "x",
                    "value": "15"
                },
                {
                    "trait_type": "y",
                    "value": "7"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci126",
        "meta": {
            "name": "Reversion #127",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Isolation"
                },
                {
                    "trait_type": "x",
                    "value": "14"
                },
                {
                    "trait_type": "y",
                    "value": "7"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci125",
        "meta": {
            "name": "Reversion #126",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Expansionist"
                },
                {
                    "trait_type": "x",
                    "value": "13"
                },
                {
                    "trait_type": "y",
                    "value": "7"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci124",
        "meta": {
            "name": "Reversion #125",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Maze Generator"
                },
                {
                    "trait_type": "x",
                    "value": "12"
                },
                {
                    "trait_type": "y",
                    "value": "7"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci123",
        "meta": {
            "name": "Reversion #124",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Stasis Growth"
                },
                {
                    "trait_type": "x",
                    "value": "11"
                },
                {
                    "trait_type": "y",
                    "value": "7"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci122",
        "meta": {
            "name": "Reversion #123",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "High Life"
                },
                {
                    "trait_type": "x",
                    "value": "10"
                },
                {
                    "trait_type": "y",
                    "value": "7"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci121",
        "meta": {
            "name": "Reversion #122",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Inversion"
                },
                {
                    "trait_type": "x",
                    "value": "9"
                },
                {
                    "trait_type": "y",
                    "value": "7"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci120",
        "meta": {
            "name": "Reversion #121",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Conway's Game of Life"
                },
                {
                    "trait_type": "x",
                    "value": "8"
                },
                {
                    "trait_type": "y",
                    "value": "7"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci12",
        "meta": {
            "name": "Reversion #13",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Overcrowding"
                },
                {
                    "trait_type": "x",
                    "value": "12"
                },
                {
                    "trait_type": "y",
                    "value": "0"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci119",
        "meta": {
            "name": "Reversion #120",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Randomness"
                },
                {
                    "trait_type": "x",
                    "value": "7"
                },
                {
                    "trait_type": "y",
                    "value": "7"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci118",
        "meta": {
            "name": "Reversion #119",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Stability"
                },
                {
                    "trait_type": "x",
                    "value": "6"
                },
                {
                    "trait_type": "y",
                    "value": "7"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci117",
        "meta": {
            "name": "Reversion #118",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Overcrowding"
                },
                {
                    "trait_type": "x",
                    "value": "5"
                },
                {
                    "trait_type": "y",
                    "value": "7"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci116",
        "meta": {
            "name": "Reversion #117",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Loneliness"
                },
                {
                    "trait_type": "x",
                    "value": "4"
                },
                {
                    "trait_type": "y",
                    "value": "7"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci115",
        "meta": {
            "name": "Reversion #116",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Revival"
                },
                {
                    "trait_type": "x",
                    "value": "3"
                },
                {
                    "trait_type": "y",
                    "value": "7"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci114",
        "meta": {
            "name": "Reversion #115",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Fast Decay"
                },
                {
                    "trait_type": "x",
                    "value": "2"
                },
                {
                    "trait_type": "y",
                    "value": "7"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci113",
        "meta": {
            "name": "Reversion #114",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Slow Growth"
                },
                {
                    "trait_type": "x",
                    "value": "1"
                },
                {
                    "trait_type": "y",
                    "value": "7"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci112",
        "meta": {
            "name": "Reversion #113",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Dense Life"
                },
                {
                    "trait_type": "x",
                    "value": "0"
                },
                {
                    "trait_type": "y",
                    "value": "7"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci111",
        "meta": {
            "name": "Reversion #112",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Isolation"
                },
                {
                    "trait_type": "x",
                    "value": "15"
                },
                {
                    "trait_type": "y",
                    "value": "6"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci110",
        "meta": {
            "name": "Reversion #111",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Expansionist"
                },
                {
                    "trait_type": "x",
                    "value": "14"
                },
                {
                    "trait_type": "y",
                    "value": "6"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci11",
        "meta": {
            "name": "Reversion #12",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Loneliness"
                },
                {
                    "trait_type": "x",
                    "value": "11"
                },
                {
                    "trait_type": "y",
                    "value": "0"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci109",
        "meta": {
            "name": "Reversion #110",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Maze Generator"
                },
                {
                    "trait_type": "x",
                    "value": "13"
                },
                {
                    "trait_type": "y",
                    "value": "6"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci108",
        "meta": {
            "name": "Reversion #109",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Stasis Growth"
                },
                {
                    "trait_type": "x",
                    "value": "12"
                },
                {
                    "trait_type": "y",
                    "value": "6"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci107",
        "meta": {
            "name": "Reversion #108",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "High Life"
                },
                {
                    "trait_type": "x",
                    "value": "11"
                },
                {
                    "trait_type": "y",
                    "value": "6"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci106",
        "meta": {
            "name": "Reversion #107",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Inversion"
                },
                {
                    "trait_type": "x",
                    "value": "10"
                },
                {
                    "trait_type": "y",
                    "value": "6"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci105",
        "meta": {
            "name": "Reversion #106",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Conway's Game of Life"
                },
                {
                    "trait_type": "x",
                    "value": "9"
                },
                {
                    "trait_type": "y",
                    "value": "6"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci104",
        "meta": {
            "name": "Reversion #105",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Randomness"
                },
                {
                    "trait_type": "x",
                    "value": "8"
                },
                {
                    "trait_type": "y",
                    "value": "6"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci103",
        "meta": {
            "name": "Reversion #104",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Stability"
                },
                {
                    "trait_type": "x",
                    "value": "7"
                },
                {
                    "trait_type": "y",
                    "value": "6"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci102",
        "meta": {
            "name": "Reversion #103",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Overcrowding"
                },
                {
                    "trait_type": "x",
                    "value": "6"
                },
                {
                    "trait_type": "y",
                    "value": "6"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci101",
        "meta": {
            "name": "Reversion #102",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Loneliness"
                },
                {
                    "trait_type": "x",
                    "value": "5"
                },
                {
                    "trait_type": "y",
                    "value": "6"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci100",
        "meta": {
            "name": "Reversion #101",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Revival"
                },
                {
                    "trait_type": "x",
                    "value": "4"
                },
                {
                    "trait_type": "y",
                    "value": "6"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci10",
        "meta": {
            "name": "Reversion #11",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Revival"
                },
                {
                    "trait_type": "x",
                    "value": "10"
                },
                {
                    "trait_type": "y",
                    "value": "0"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci1",
        "meta": {
            "name": "Reversion #2",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Inversion"
                },
                {
                    "trait_type": "x",
                    "value": "1"
                },
                {
                    "trait_type": "y",
                    "value": "0"
                }
            ]
        }
    },
    {
        "id": "fe475846c444ffb7fa0aad04d6bca77b602696b7d167062cb7f9ce2a5855d34ci0",
        "meta": {
            "name": "Reversion #1",
            "attributes": [
                {
                    "trait_type": "rule",
                    "value": "Conway's Game of Life"
                },
                {
                    "trait_type": "x",
                    "value": "0"
                },
                {
                    "trait_type": "y",
                    "value": "0"
                }
            ]
        }
    }
]
